const customerSupport = {
  title: 'customer support',

  actions: {
    search: {
      openNewTicketBtn: 'open new ticket',
    },
  },

  ticket: {
    lastUpdate: 'last update',
  },

  form: {
    create: {
      title: 'new ticket',
      fields: {
        title: {
          displayName: 'title',
          placeholder: 'add ticket title...',
        },
        category: {
          displayName: 'category',
          placeholder: 'choose category...',
          categoryOptions: {
            rapidIRInvestigator: 'Rapid-IR Investigator',
            integrations: 'Integrations',
            others: 'Others',
          },
        },
        description: {
          displayName: 'description',
          placeholder: 'add ticket description...',
        },
        addFile: 'add file',
        uploadFile: 'upload file (max file size: 5MB)',
      },
      footer: {
        confirm: 'add ticket',
      },

      successToast: {
        message: 'Ticket created successfully',
      },
    },
  },

  state: {
    noPermission: {
      readList: 'You have no permission to view the list of tickets',
    },
    emptyClosedTickets: {
      title: 'No Closed Tickets Yet',
      description: 'After a ticket is resolved, it will move here<br/>so you can revisit it whenever needed.',
    },
    emptyOpenedTickets: {
      title: 'No Open Tickets Yet',
      description: 'You don’t have any open tickets at the moment.<br/>Create a new one if you need assistance.',
      openNewTicketBtnText: 'open new ticket',
    },
    search: {
      noResults: 'No Results',
    },
  },
};

export default customerSupport;
