const common = {
  page: {
    noResults: 'No Results',
  },
  table: {
    generalSearch: 'Search',
    noResults: 'No Results',
  },

  errorToast: {
    message: 'somthing went wrong',
  },

  errorsMessages: {
    status404: 'technical issues please contact support',
    status400: 'technical issues please contact support',
    status500: 'internal server error please contact support',
    status429: 'too many requests',
    status401: 'invalid token or session',
    status403: 'access denied',
    other: 'something went wrong please contact support',
    status401ForGetJWT: 'bad token',
  },

  pageSubject: {
    home: 'home',
    fileSharing: 'file sharing',
    configuration: 'configurations',
    sensorsInventory: 'sensors inventory',
    threatHunting: 'threat hunting',
    incidents: 'incidents',
    settings: 'settings',
    readiness: 'readiness',
  },

  invalidTokenOrSessionToast: {
    message: 'Invalid token or session',
  },

  tooManyRequests: {
    message: 'too many requests',
  },

  noPermission: {
    display: 'You have no permission to view',
  },
};

export default common;
